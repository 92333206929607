import React from "react";
import { useDispatch } from "react-redux";
import { deleteMyQuestion } from "../../features/qna/questionSlice";
import Swal from "sweetalert2";

function MyQuestionCard({ question }) {
  const dispatch = useDispatch();
  const dateNow = new Date();
  const expired = new Date(question.expired_time);
  const date = new Date(question.date);
  const dateTime =
    date.getFullYear() +
    "/" +
    ("00" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    ("00" + date.getDate()).slice(-2) +
    " " +
    ("00" + date.getHours()).slice(-2) +
    ":" +
    ("00" + date.getMinutes()).slice(-2) +
    ":" +
    ("00" + date.getSeconds()).slice(-2);

  const deleteHandler = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (result.isConfirmed) {
      dispatch(deleteMyQuestion(id));
    }
  };

  return (
    <div className="flex border-b-2 py-2 gap-2 border-emerald-500 w-full items-center ">
      <div className=" w-28">
        <p>{dateTime}</p>
      </div>
      <p className=" text-lg font-bold lg:text-xl w-full">{question.title}</p>
      <div className=" flex gap-2 ">
        {question.is_answer ? (
          <p className=" bg-green-300 rounded-md text-white px-3 py-1 h-10">
            answer
          </p>
        ) : (
          <p className=" bg-red-400 rounded-md text-white px-3 py-1 h-10">
            answer
          </p>
        )}
        {dateNow > expired || question.is_answer ? (
          <div className="w-6 h-6"></div>
        ) : (
          <button onClick={() => deleteHandler(question.idquestion)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
              />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
}

export default MyQuestionCard;
