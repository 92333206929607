import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function Sidebar() {
  const navigate = useNavigate();

  const handleLogoutAdmin = () => {
    localStorage.clear();
    navigate("/admin/login");
    Swal.fire({
      icon: "success",
      title: "Logout",
      text: "You already logout from Dashboard Pharmacy Web",
    });
  };

  return (
    <div
      style={{ width: "inherit" }}
      className="flex flex-col justify-between fixed top-0 left-0 z-40 h-screen p-4 overflow-y-auto transition-transform dark:bg-gray-800 bg-white shadow-side-bar-admin "
    >
      <div className="flex flex-col gap-11">
        <div className="hidden lg:block mx-10 mt-6">
          <Link to={"/admin/dashboard"}>
            <img
              src="/assets/logo-pharmacy.png"
              alt="pharmacy"
              className="logo-image"
            />
          </Link>
        </div>
        <div className="flex flex-col gap-11 text-slate-400 text-lg font-bold lg:mx-10">
          <NavLink
            to="/admin/dashboard"
            className={({ isActive, isPending }) =>
              isPending ? "" : isActive ? "text-color-green" : ""
            }
          >
            <div className="flex gap-6 hover:cursor-pointer items-center hover-text-green">
              <div className="w-7">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 01-.657.643 48.39 48.39 0 01-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 01-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 00-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 01-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 00.657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 01-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 005.427-.63 48.05 48.05 0 00.582-4.717.532.532 0 00-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 00.658-.663 48.422 48.422 0 00-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 01-.61-.58v0z"
                  ></path>
                </svg>
              </div>
              <p className="hidden lg:block">Dashboard</p>
            </div>
          </NavLink>

          <NavLink
            to="/admin/products"
            className={({ isActive, isPending }) =>
              isPending ? "" : isActive ? "text-color-green" : ""
            }
          >
            <div className="flex gap-6 hover:cursor-pointer items-center hover-text-green">
              <div className="w-7">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.75 3.104v5.714a2.25 2.25 0 01-.659 1.591L5 14.5M9.75 3.104c-.251.023-.501.05-.75.082m.75-.082a24.301 24.301 0 014.5 0m0 0v5.714c0 .597.237 1.17.659 1.591L19.8 15.3M14.25 3.104c.251.023.501.05.75.082M19.8 15.3l-1.57.393A9.065 9.065 0 0112 15a9.065 9.065 0 00-6.23-.693L5 14.5m14.8.8l1.402 1.402c1.232 1.232.65 3.318-1.067 3.611A48.309 48.309 0 0112 21c-2.773 0-5.491-.235-8.135-.687-1.718-.293-2.3-2.379-1.067-3.61L5 14.5"
                  ></path>
                </svg>
              </div>
              <p className="hidden lg:block">Product</p>
            </div>
          </NavLink>
          <NavLink
            to="/admin/transactions"
            className={({ isActive, isPending }) =>
              isPending ? "" : isActive ? "text-color-green" : ""
            }
          >
            <div className="flex gap-6 hover:cursor-pointer items-center hover-text-green">
              <div className="w-7">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                  ></path>
                </svg>
              </div>
              <p className="hidden lg:block">Transaction</p>
            </div>
          </NavLink>
          <NavLink
            to="/admin/answer-question"
            className={({ isActive, isPending }) =>
              isPending ? "" : isActive ? "text-color-green" : ""
            }
          >
            <div className="flex gap-6 hover:cursor-pointer items-center hover-text-green">
              <div className="w-7">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                  ></path>
                </svg>
              </div>
              <p className="hidden lg:block">Answer Question</p>
            </div>
          </NavLink>
          <NavLink
            to="/admin/report"
            className={({ isActive, isPending }) =>
              isPending ? "" : isActive ? "text-color-green" : ""
            }
          >
            <div className="flex gap-6 hover:cursor-pointer items-center hover-text-green">
              <div className="w-7">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605"
                  ></path>
                </svg>
              </div>
              <p className="hidden lg:block">Report</p>
            </div>
          </NavLink>
          <NavLink
            to="/admin/discounts"
            className={({ isActive, isPending }) =>
              isPending ? "" : isActive ? "text-color-green" : ""
            }
          >
            <div className="flex gap-6 hover:cursor-pointer items-center hover-text-green">
              <div className="w-7">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V13.5zm0 2.25h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V18zm2.498-6.75h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V13.5zm0 2.25h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V18zm2.504-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zm0 2.25h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V18zm2.498-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zM8.25 6h7.5v2.25h-7.5V6zM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0012 2.25z"
                  />
                </svg>
              </div>
              <p className="hidden lg:block">Promo</p>
            </div>
          </NavLink>
          <NavLink
            to="/admin/history-stock"
            className={({ isActive, isPending }) =>
              isPending ? "" : isActive ? "text-color-green" : ""
            }
          >
            <div className="flex gap-6 hover:cursor-pointer items-center hover-text-green">
              <div className="w-7">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3"
                  ></path>
                </svg>
              </div>
              <p className="hidden lg:block">History Stock</p>
            </div>
          </NavLink>
        </div>
      </div>
      <div
        onClick={handleLogoutAdmin}
        className="flex gap-2 hover:cursor-pointer py-2 justify-center rounded-md text-lg lg:mx-auto mb-6 font-bold lg:w-1/2 logout-admin-button"
      >
        <div className="w-7">
          <svg
            fill="none"
            stroke="currentColor"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
            ></path>
          </svg>
        </div>
        <p className="hidden lg:block">Logout</p>
      </div>
    </div>
  );
}

export default Sidebar;
